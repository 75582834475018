.input__data_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
}

.input__intro {
    width: 100%;
    height: 50px;
    font-size: 18px;
    border-radius: 6px;
    padding: 12px 16px;
    background-color: #f1f4f9;
}

.input__new_design {
    width: 100%;
    height: 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 12px 20px rgba(18, 115, 243, 0.116);
}

.input__new_design:focus-visible {
  outline: none;
}

.input__new_design::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #A8B1BD;
}

.input__new_design.error_field {
    border: 1px solid #F45532;
}

.input__label {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1A2E4F;
    margin-bottom: 6px;
    margin-top: 0;
}

.input__address {
    margin-bottom: 12px;
    background-color: #f1f4f9;
}

.inputs__block {
    width: 100%;
}

.input_container {
    width: 100%;
    margin-top: 24px;
}

.verificationFields_block {
    display: flex;

    >input {
        width: 50px;
    }
}

.input__wrapper{
    display: flex;
    align-items: center;
}

.field__wrapper{
    display: flex;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
}

.input__data {
    width: 45%;
    height: 50px;
    padding: 12px 16px;
    font-size: 18px;
    border-radius: 6px;
    background-color: #f1f4f9;
}

.input__file {
    background-color: #f1f4f9;
    border-radius: 6px;
    font-size: 18px;
    height: 50px;
    padding: 12px 16px;
    border: none;
    margin-bottom: 20px;
    width: 100%;
}

.label__terms{
    margin-top: 6px;
    margin-left: 6px;
}
  
.terms{
    margin-top: 6px;
    color: #2c64ef;
    margin-left: 6px;
}

.terms__box {
    appearance: auto !important;
    margin-right: 5px;
}

.input__data_select {
    width: 100%;
}

.input__message {
    width: 100%;
    height: 100px;
    border-radius: 12px;
    padding: 12px;
    font-size: 18px;
    margin: 0 0 12px 0;
}
  
.input__password{
    border-radius: 12px 0 0 12px;
}
  
.password__rules{
    color: gray;
    margin-top: 8px;
    margin-left: 4px;
    font-size: 14px;
    display: flex;
}
  
.password__icon_wrapper{
    display: flex;
    align-items: center;
    background-color: #f1f4f9;
    height: 50px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-right: 4px;
}

.password__eye_icon_wrapper{
    display: flex;
    align-items: center;
    background-color: transparent;
    height: 42px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 7px;
    position: absolute;
    right: 1px;
    top: 1px;
}
  
.password__icon.MuiSvgIcon-root{
    color: gray;
    font-size: 35px;
    cursor: pointer;
}
  
.right__rules{
    margin-left: 12px;
}