.p {
  margin: 0 0 16px;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.15px;
}

.foot_name {
  font-weight: 600;
}
