.actions_block {
  padding: 12px 22px 22px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 358px;
  height: 201px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 28px;
}

.text {
  font-weight: 510;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(255, 255, 255, 0.5);
}

.instructions_button {
  width: 162px;
  height: 50px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #BDE0FE;
  color: #000;
  border: 1.75px solid #8ECAE6;
  border-radius: 100px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.question_mark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  color: #BDE0FE;
}

.flex_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}