.text_field {
  display: block;
  width: 100%;
  position: relative;
}

.input {
  display: block;
  width: 100%;
  padding: 0 18px;
  font-weight: 300;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.15px;
  border: 0.5px solid #6F797B;
  border-radius: 24px;
  background-color: transparent;
}

.input::placeholder {
  color: #000;
}

.input_fixed_width {
  width: 75%;
}

.input:focus-visible {
  outline: none;
}

.inputs_wrapper {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.text_label {
  font-weight: 600;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 0.15px;
}

.form_title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.15px;
}

.form {
  &_inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
  }
}

.height_block {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.terms_label {
  font-weight: 274;
  font-size: 14px;
  line-height: 48px;
}

.autocomplete_block {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  max-width: 560px;
}

.autocomplete_list {
  border-radius: 12px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  background-color: #f8f9fa;
  width: 100%;
}

.autocomplete_item {
  padding: 5px;
}
