.forgotpass__section {
  background: #F5F5F5;
}

.forgotpass__wrapper{
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  height: calc(100vh - 80px);
  max-height: calc(100svh - 80px);
}

.forgotpass__fields__wrapper{
  height: 100%;
}

.forgotpass_title_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;

  .forgotpass_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #1A2E4F;
    margin-bottom: 15px;
  }
  .forgotpass_sub_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #4D6181;
  }
}

.forgotpass__button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 46%;
  gap: 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 14px 29px rgba(18, 116, 243, 0.20);
  border-radius: 8px;
}

.modal_title_wrapper {
  padding: 24px 16px;
  border-bottom: 1px solid #A8B1BD;
}

.modal_title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1A2E4F;
  margin: 0 0 4px 0
}

.modal_subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4D6181;
  margin: 0
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 100%;
}

.checkEmailModal {
  position: fixed;
  overflow: hidden;
  display: grid;
  place-items: flex-end;
  height: min(calc(45vw + 5rem), calc(45vh + 5rem));
  width: min(80vw, 80vh);
  left: 50%;
  transform: translateX(-50%);
  top: 5.2rem;
  z-index: 1000;
  border-radius: 1rem;
  max-width: 1232px;
}

.verification_error {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #F45532;
  margin-top: 12px;
}