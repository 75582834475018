.describe__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    color: black;
    width: 100%;
    margin: 12px 0 12px 0;
}
  
.describe__pain {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 6px;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(--disabled-bg-color);
}
  
.describe__pain_checked {
    background-color: var(--main-bg-color);
    color: #fff;
}

.insole_describe__wrapper {
    display: flex;
    flex-wrap: wrap;
    color: black;
    width: 100%;
    margin: 0;
}

.insole_describe__pain {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #241A00;
    margin: 7px 8px 7px 0;
    padding: 8px 14px;
    border-radius: 28px;
    cursor: pointer;
    background-color: #fff;
    border: 0.5px solid #6F797B
}
  
.insole_describe__pain_checked {
    background-color: #FFE08E;
    color: #241A00;
}