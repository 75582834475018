.image__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image__title {
    margin: 12px 0;
}

.image__captured {
    margin-bottom: 12px;
}

.webcam__wrapper {
    height: 100%;
    position: relative;
    z-index: 0;
}

.webcam_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video__wrapper {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.video__example {
    margin-bottom: 12px;
    border-radius: 20px;
    width: 100%;
}

.shape__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.capture__title {
    position: absolute;
    z-index: 1;
    top: 3%;
    width: 100%;
    color: white;
    font-size: 24px;
    text-align: center;
}

.preview__wrapper{
    padding: 20px 0;
}

.capture__frame {
    position: absolute;
    bottom: 25%;
    left: 20%;
    z-index: 1;
    height: 60%;
    width: 60%;
    border: 4px solid #00e092;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoCapture__frame {
    position: absolute;
    bottom: 25%;
    left: 20%;
    z-index: 1;
    height: 60%;
    width: 60%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.capture__timer{
    color: white;
    font: bold;
    font-size: 60px;
}

.preview {
    height: calc(100svh - 115px);
    margin-bottom: 39px;
}

.preview_wrapper{
    height: 100svh;
}
