.error{
    color: red;
    margin-top: 6px;
    margin-left: 12px;
}

.field_error {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #F45532;
    margin-top: 10px;
    margin-left: 12px;
}

.input_error {
    border: 1px solid #F45532;
}