.choose__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.diabetes__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.choose__title{
  margin-bottom: 12px;
  text-align: center;
  font-size: 20px;
}

.choose__img{
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
}

.diabetes__card_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1D1B20;
}

.diabetes__card_subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #49454F;
}