.form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.form__login_wrapper {
    height: 100vh;
    max-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
}

.form__btn_container {
    display: flex;
    margin-top: 24px;
    margin-bottom: 100px;
    padding: 0 20px;
}

.forgotpass__link {
    font-style: normal;
    font-weight: 510;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
    border: 1px solid #a9adad;
    border-radius: 24px;
    padding: 15px;
}

.form__text_loginWith {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #1A2E4F;
}

.form__text_loginWith:last-child {
    margin-left: 15px;
}

.form__text_register {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #4D6181;

    >a {
        color: #2C64EF;
        font-weight: 600;
        margin-left: 10px;
    }
}

.form__title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.15px;
    color: #000000;
    margin: 0 30px 14px 0;
}

.form__shoeinfo_title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.15px;
    color: #000000;
    margin: 0 0 30px;
}

@media (max-width: 414px) {
    .form__shoeinfo_title {
        font-size: 30px;
        line-height: 34px;
        margin: 0 0 20px;
    }
}

@media (max-width: 375px) {
    .form__shoeinfo_title {
        font-size: 27px;
        line-height: 32px;
        margin: 0 0 20px;
    }
}