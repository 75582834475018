.tutorial {
  padding: 69px 19px 32px;
  margin: 0 16px;
  background-color: #EFF5F6;
  border: 0.25px solid #000000;
  border-radius: 28px;

  &_paragraph {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #49454F;
  }

  &_player {
    height: 220px;
    margin-top: 38px;
    margin-bottom: 16px;
    background-color: #E3F2FD;
    border: 0.5px solid #6F797B;
    border-radius: 28px;
    overflow: hidden;
  }

  &_button_block {
    width: min-content;
    margin-left: auto;
  }
}
