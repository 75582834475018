.rating__row{
    box-shadow: 0px 12px 20px rgba(18, 116, 243, 0.20);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
}
  
.rating__number{
    padding: 12px 8px;
    cursor: pointer;
}
  
.rating__number_active {
    background-color: #EAF1FB;
    color: #1374F6;
}