.auth_buttons_block {
    display: flex;
    width: 100%;
    gap: 15px;
}

.intercom__button {
    font-size: 20px;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid #b0b6be;
    color: #2c64ef;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    text-align: center;
}

.modal__button {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2C64EF;
    padding: 12px 16px;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.forgotpass__button {
    padding: 12px 16px;
    background: #2C64EF;
    box-shadow: 0px 12px 20px rgba(18, 116, 243, 0.12);
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    margin-bottom: 30px;
    width: 100%;
}

.forgotpass__sign_button {
    padding: 12px 16px;
    background: #FFFFFF;
    box-shadow: 0px 12px 20px rgba(18, 116, 243, 0.12);
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #2C64EF;
    border: none;
    margin-bottom: 30px;
    width: 100%;
}

.forgotpass__sign_button:last-child {
    margin-left: 15px;
}
  
.carousel__button{
    font-size: 20px;
    padding: 6px 8px;
    background-color: #2c64ef;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}
  
.carousel__button_prev{
    font-size: 20px;
    padding: 6px 8px;
    background-color: white;
    color: #2c64ef;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    border: 1px solid #b0b6be;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.next__button {
    background-color: #2c64ef;
    border-radius: 4px;
    margin: 12px 0 48px 0;
    width: 100%;
    height: 50px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
}

.disabled {
    background-color: lightgrey;
}

.button_disabled {
    background-color: lightgrey;
}

.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.03em;
    line-height: 12px;
    background-color: var(--disabled-bg-color);
    border-radius: 6px;
    color: #49505a;
    cursor: pointer;
}
  
.button_active {
    background-color: var(--main-bg-color);
    color: #fff;
}

.button_colored {
    width: 169px;
    height: 56px;
    border-radius: 28px;
    border: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.button_colored:disabled {
    background-color: #8e9394 !important;
}

.button_outlined {
    width: 169px;
    height: 56px;
    background: #FFFFFF;
    border: 0.25px solid #6F797B;
    border-radius: 28px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.button_fab {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #DBE4E6;
    color: black;
}

.camera__button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: white;
    cursor: pointer;
}
  
.capture__button {
    position: absolute;
    top: 85%;
    left: 40%;
    width: 75px;
    height: 75px;
    background-color: transparent;
    border: 4px solid white;
}

.back__button {
    position: absolute;
    top: 86.5%;
    left: 10%;
    width: 50px;
    height: 50px;
    background-color: #2c64ef;
}

.flip__button {
    position: absolute;
    top: 86.5%;
    right: 10%;
    width: 50px;
    height: 50px;
    background-color: #2c64ef;
}

.website__button {
    color: white;
}

.logOut__button {
    padding-top: 80px !important;
}

.logOut_nav__button {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}