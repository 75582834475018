.feedback__text {
    height: 100px;
    resize: none;
    padding: 16px;
    box-shadow: 0px -27px 59px rgba(18, 116, 243, 0.03), 0px 24px 46px rgba(18, 116, 243, 0.20);
    border-radius: 8px;
    border: none;
}
  
.feedback__para {
    margin: 12px 0;
    font-weight: 600;
}
  
.past_insoles_yes,
.past_insoles_no {
    margin-bottom: 6px;
    margin-right: 16px;
}