/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  transition: all 300ms ease;
}

:root {
  --main-bg-color: #2c64ef;
  --disabled-bg-color: #eef1f6;
}

input[type='checkbox' i] {
  appearance: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #F5F5F5;
}

video img {
  display: none;
}

.container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.content_centered {
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}

li {
  list-style-type: none;
}

input {
  border: none;
}

h3 {
  color: black;
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 24px;
}

h2 {
  color: black;
  margin-top: 12px;
  font-size: 36px;
}

section {
  display: block;
}

select {
  background-color: #f1f4f9;
  color: black;
  width: 100%;
  height: 50px;
  padding: 12px 16px;
  font-size: 18px;
  border-radius: 6px;
  border: none;
}

a {
  text-decoration: none;
  color: black;
}

button {
  border: none;
  outline: none;
}

path,
circle {
  cursor: pointer;
}

.section__title {
  color: black;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  margin-bottom: 12px;
}

.customModal {
  position: fixed;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(48, 48, 48, 0.2);
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}

.input_custom {
  width: 25% !important;
  height: 44px;
  border-radius: 16px;
  padding: 12px 16px;
  background-color: #FFFFFF;
  margin-right: 16px;
  box-shadow: 0px 12px 20px rgba(18, 116, 243, 0.12);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #1A2E4F;
}

.input_custom::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #A8B1BD;
}

.input_custom:last-child {
  margin-right: 0;
}

.input_custom:focus-visible {
  outline: none;
  border: 1px solid #2C64EF;
}

.input_custom.error {
  border: 1px solid #F45532;
}

.customModal__inner {
  max-width: 320px;
  background-color: white;
  border-radius: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MEDIA QUERY */

@media (max-width: 540px) {

  h3 {
    font-size: 18px;
  }

  .input__intro {
    font-size: 16px;
  }

  .input__data {
    font-size: 16px;
  }

  .landing__title {
    font-size: 30px;
  }

  .landing__para {
    font-size: 16px;
  }

  .describe__pain {
    font-size: 14px;
  }

  .pain__para {
    font-size: 14px;
  }

  .section__title {
    font-size: 24px;
  }

  .confirm__img {
    width: 200px;
  }

  .confirm__subtitle {
    font-size: 20px;
  }

  .intercom__button {
    font-size: 12px;
    font-weight: 500;
  }

  .capture__title {
    left: 35%;
  }
}

.shoeSize .picker-container .picker-inner {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000 !important;
  text-transform: capitalize;
}

.shoeWidth .picker-container .picker-inner {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000 !important;
  text-transform: capitalize;
}

.picker-container .picker-highlight {
  display: none;
}

.shoeSize {
  height: 320px;
}

.shoeWidth, .shoeBrand {
  height: 150px !important;
}

.shoeSize .rmc-picker-indicator {
  height: 70px;
  top: 80px !important
}

.shoeSize .rmc-picker-content {
  padding: 80px 0 !important;
}

.shoeSize .rmc-picker-indicator,
.shoeWidth .rmc-picker-indicator {
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
}

.shoeSize .rmc-picker-mask,
.shoeWidth .rmc-picker-mask {
  border-radius: 28px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9)), linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
}

.shoeSize .rmc-picker-mask {
  background-size: 100% 90px !important;
}

.shoeSize .rmc-picker-item {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000 !important;
  text-transform: capitalize;
}

.shoeWidth .rmc-picker-indicator {
  top: 40px !important
}

.shoeWidth .rmc-picker-content {
  padding: 40px 0 !important;
}

.shoeWidth .rmc-picker-mask {
  background-size: 100% 44px !important;
}

.shoeWidth .rmc-picker-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000 !important;
  text-transform: capitalize;
}

